#react-tiny-toast-main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.toast-container {
  position: fixed;
}
.toast-container.top-center {
  top: 0px;
  -webkit-align-self: center;
          align-self: center;
}
.toast-container.top-left {
  top: 0px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.toast-container.top-right {
  top: 0px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.toast-container.bottom-center {
  -webkit-align-self: center;
          align-self: center;
  bottom: 0px;
}
.toast-container.bottom-left {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  bottom: 0px;
}
.toast-container.bottom-right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  bottom: 0px;
}
@-webkit-keyframes toast-animation-from-top {
  from {
    top: 100px;
    opacity: 0.8;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@-webkit-keyframes toast-animation-from-bottom {
  from {
    bottom: 100px;
    opacity: 0.8;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes toast-animation-from-top {
  from {
    top: 100px;
    opacity: 0.8;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes toast-animation-from-bottom {
  from {
    bottom: 100px;
    opacity: 0.8;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
.toast-item-animation-top {
  -webkit-animation: toast-animation-from-top 0.5s ease-in-out;
          animation: toast-animation-from-top 0.5s ease-in-out;
}
.toast-item-animation-bottom {
  -webkit-animation: toast-animation-from-bottom 0.5s ease-in-out;
          animation: toast-animation-from-bottom 0.5s ease-in-out;
}
.toast-item {
  position: relative;
  padding: 8px;
  margin: 2px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  box-shadow: -4px -4px 16px -6px rgba(0,0,0,1);
}
.toast-item-success {
  background-color: #28a745;
}
.toast-item-danger {
  background-color: #dc3545;
}
.toast-item-default {
  background-color: #6c757d;
  color: #f8f9fa;
}
.toast-item-warning {
  background-color: #ffc107;
}
